<template>
  <div>
    <div
      class="d-flex justify-content-center align-items-center"
      v-if="
        showPreloader ||
        $store.state.refresh.fetchingNewData ||
        $store.state.refresh.waiting_filter
      "
      style="height: 90vh"
    >
      <b-spinner variant="primary" label="Text Centered" />
    </div>
    <div v-else>
      <tableDriverPhoto
        :photo="photo"
        @open="open"
        @getPhotoTreatment="getPhotoTreatment"
        @getChangeType="getChangeType"
        @getBlock="getBlock"
      />
    </div>
    <modal-photo
      :performer_id="performer_id"
      :performer_assign_p_c_id="performer_assign_p_c_id"
      :img="img"
      @getphoto="getphoto"
    ></modal-photo>
    <modal-photo-treatment
      :data="data"
      @getphoto="getphoto"
    ></modal-photo-treatment>
    <modal-change-type :data="data" @getphoto="getphoto"></modal-change-type>
    <modal-driver-edit
      :driver_id="driver_id"
      @getphoto="getphoto"
    ></modal-driver-edit>
  </div>
</template>

<script>
import ModalPhoto from "./ModalPhoto.vue";
import modalChangeType from "@/views/component/Modal/ModalPhotocontrol/modalChangeType.vue";
import modalPhotoTreatment from "@/views/component/Modal/ModalPhotocontrol/modalPhotoTreatment.vue";
import modalDriverEdit from "@/views/component/Modal/ModalDriver/modalDriverEdit.vue";
import tableDriverPhoto from "@/views/component/Table/tableDriverPhoto.vue";
export default {
  components: {
    ModalPhoto,
    modalPhotoTreatment,
    modalChangeType,
    modalDriverEdit,
    tableDriverPhoto,
  },
  data() {
    return {
      driver_id: this.$route.query.driver_id,
      performer_id: this.$route.query.driver_id,
      performer_assign_p_c_id: "",
      img: "",
      showPreloader: false,
      data: [],
      photo: [],
    };
  },
  mounted() {
    this.$store.commit("pageData/setdataCount", null);
    this.getphoto();
  },
  methods: {
    getphoto() {
      this.$http
        .get(`photo-control/performer/${this.performer_id}`)
        .then((res) => {
          this.photo = res.data;
          this.$store.commit("pageData/setdataCount", this.photo.length);
          this.$store.commit("REFRESH_DATA", false);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    open(item) {
      this.performer_assign_p_c_id = item.performer_assign_p_c_id;
      this.img = item.url;
      setTimeout(this.openModal, 0);
    },
    getBlock() {
      setTimeout(this.bloc, 0);
    },
    getPhotoTreatment(data) {
      this.data = data;
      setTimeout(this.treatment, 0);
    },
    getChangeType(data) {
      this.data = data;
      setTimeout(this.changeType, 0);
    },
    bloc() {
      this.$bvModal.show("modalDriverEdit");
    },
    openModal() {
      this.$bvModal.show("modalPhoto");
    },
    treatment() {
      this.$bvModal.show("modalPhotoTreatment");
    },
    changeType() {
      this.$bvModal.show("modalChangeType");
    },
  },
  computed: {
    fetchingNewData() {
      return this.$store.state.refresh.fetchingNewData;
    },
  },
  watch: {
    fetchingNewData() {
      this.getphoto();
    },
  },
};
</script>

<style scoped lang="scss"></style>
