<template>
  <example-wrapper>
    <b-modal
      id="modalPhotoTreatment"
      title="Файлы-Редактировать"
      size="s"
      hide-footer
      no-enforce-focus
      :no-close-on-backdrop="true"
      @show="getTypePhoto()"
      @clise="btnCancel()"
      @shown="getcline()"
    >
      <cropper
        ref="cropper"
        class="cropper"
        :transitions="true"
        image-restriction="fit-area"
        :src="img"
      />
      <vertical-buttons>
        <square-button
          class="icon"
          title="Отразить по горизонтали"
          @click="flip(true, false)"
        >
          <img
            :src="
              require('@/views/default/taxi/photoControl/photo/photoIcon/flip-horizontal.svg')
            "
          />
        </square-button>

        <square-button title="Отразить по вертикали" @click="flip(false, true)">
          <img
            :src="
              require('@/views/default/taxi/photoControl/photo/photoIcon/flip-vertical.svg')
            "
          />
        </square-button>

        <square-button title="Вращаться по часовой стрелке" @click="rotate(90)">
          <img
            :src="
              require('@/views/default/taxi/photoControl/photo/photoIcon/rotate-clockwise.svg')
            "
          />
        </square-button>

        <square-button
          title="Вращать против часовой стрелки"
          @click="rotate(-90)"
        >
          <img
            :src="
              require('@/views/default/taxi/photoControl/photo/photoIcon/rotate-counter-clockwise.svg')
            "
          />
        </square-button>
        <square-button title="Фото изменено" @click="download()">
          <img
            :src="
              require('@/views/default/taxi/photoControl/photo/photoIcon/download.svg')
            "
          />
        </square-button>
      </vertical-buttons>
      <b-row class="pt-2">
        <b-col>
          <label for="">Тип файла <span class="required">*</span></label>
          <v-select
            v-model="type_photo_control_id"
            placeholder="Связка Водитель - Автомобиль"
            label="alias_for_file_name"
            :reduce="(options) => options.id"
            :options="type"
            class="select-size-md"
          >
            <template v-slot:no-options>
              <span class="no-data-text">Нет доступных файла</span>
            </template>
          </v-select>
        </b-col>
      </b-row>
      <b-row class="mt-2 mb-1">
        <b-col class="d-flex justify-content-end">
          <b-button
            @click="addTypePhoto()"
            :disabled="submitButtonDisabled"
            variant="primary"
            >Изменить</b-button
          >
          <b-button class="ml-1 mr-1" @click="delTypePhoto()" variant="danger"
            >Отмена</b-button
          >
        </b-col>
      </b-row>
    </b-modal>
  </example-wrapper>
</template>

<script>
import vSelect from "vue-select";
import "vue-advanced-cropper/dist/style.css";
import { Cropper } from "vue-advanced-cropper";
import SquareButton from "@/views/default/taxi/photoControl/photo/SquareButton.vue";
import ExampleWrapper from "@/views/default/taxi/photoControl/photo/ExampleWrapper.vue";
import VerticalButtons from "@/views/default/taxi/photoControl/photo/VerticalButtons.vue";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
export default {
  props: ["data"],
  components: {
    ExampleWrapper,
    Cropper,
    SquareButton,
    VerticalButtons,
    vSelect,
  },
  data() {
    return {
      img: "",
      type: [],
      performer_id: "",
      type_photo_control_id: "",
      performer_assign_p_c_id: "",
      bodyPhoto: [],
      bodyTypePhoto: [],
      submitButtonDisabled: false,
    };
  },
  methods: {
    getcline() {
      this.img = this.data.url;
      this.performer_id = this.data.performer_id;
      this.type_photo_control_id = this.data.type_photo_control_id;
      this.performer_assign_p_c_id = this.data.performer_assign_p_c_id;
    },
    flip(x, y) {
      const { image } = this.$refs.cropper.getResult();
      if (image.transforms.rotate % 180 !== 0) {
        this.$refs.cropper.flip(!x, !y);
      } else {
        this.$refs.cropper.flip(x, y);
      }
    },
    rotate(angle) {
      this.$refs.cropper.rotate(angle);
    },
    Browser() {
      const result = this.$refs.cropper.getResult().canvas.toDataURL();
      this.img = result;
      const newTab = window.open();
      newTab.document.body.innerHTML = `<img src="${result}"></img>`;
    },
    getTypePhoto() {
      this.$http
        .get("photo-control/type")
        .then((res) => {
          this.type = res.data;
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Ошибка!",
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.errors,
            },
          });
        });
    },
    download() {
      const result = this.$refs.cropper.getResult().canvas.toDataURL();
      this.img = result;
      this.bodyPhoto = {
        image: this.img,
        performer_assign_p_c_id: this.performer_assign_p_c_id,
      };
      this.submitButtonDisabled = true;
      this.$http
        .post(
          `photo-control/performer/${this.data.performer_id}/image`,
          this.bodyPhoto
        )
        .then(() => {
          this.submitButtonDisabled = false;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Успех!",
              icon: "CheckIcon",
              variant: "success",
              text: "Фото изменено",
            },
          });
          this.delTypePhoto();
          this.$emit("getphoto");
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Ошибка!",
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.errors,
            },
          });
        })
        .finally(() => {
          this.submitButtonDisabled = false;
        });
    },
    addTypePhoto() {
      this.bodyTypePhoto = {
        performer_id: this.performer_id,
        type_photo_control_id: this.type_photo_control_id,
        performer_assign_p_c_id: this.performer_assign_p_c_id,
      };
      this.$http
        .post(
          `photo-control/performer/${this.data.performer_id}/image-replace`,
          this.bodyTypePhoto
        )
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Успех!",
              icon: "CheckIcon",
              variant: "success",
              text: `Тип фотоконтроля изменён`,
            },
          });
          this.delTypePhoto();
          this.$emit("getphoto");
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Ошибка!",
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.errors,
            },
          });
        });
    },
    delTypePhoto() {
      this.$bvModal.hide("modalPhotoTreatment");
      this.img = "";
      this.type = [];
      this.type_photo_control_id = "";
      this.performer_assign_p_c_id = "";
      this.bodyPhoto = [];
      this.bodyTypePhoto = [];
    },
  },
};
</script>

<style scoped>
.required {
  color: red;
}
</style>
