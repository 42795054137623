<template>
  <div>
    <example-wrapper
    >
    <b-modal 
    id="modalPhoto"
    title="Обработка фото"
    size="s"
    hide-footer no-enforce-focus 
    :no-close-on-backdrop="true"
    @show="getphoto()"
    @close="btnCancel()"
    >
          <cropper 
          ref="cropper" 
          class="cropper"
          :transitions="true"
          image-restriction="fit-area" 
          :src="img" 
          />
      <vertical-buttons>
            <square-button class="icon" title="Отразить по горизонтали" @click="flip(true, false)">
				            <img   :src="require('@/views/default/taxi/photoControl/photo/photoIcon/flip-horizontal.svg')" />
		        </square-button>

            <square-button  title="Отразить по вертикали" @click="flip(false, true)">
			            	<img :src="require('@/views/default/taxi/photoControl/photo/photoIcon/flip-vertical.svg')" />
	      		</square-button>

            <square-button title="Вращаться по часовой стрелке" @click="rotate(90)">
				        <img :src="require('@/views/default/taxi/photoControl/photo/photoIcon/rotate-clockwise.svg')" />
			      </square-button>

			      <square-button title="Вращать против часовой стрелки"  @click="rotate(-90)">
				        <img :src="require('@/views/default/taxi/photoControl/photo/photoIcon/rotate-counter-clockwise.svg')" />
            </square-button>
            <square-button  title="Окрыть на браузер" @click="Browser()">
				        <img :src="require('@/views/default/taxi/photoControl/photo/photoIcon/download.svg')" />
			      </square-button>
      </vertical-buttons>
          <b-row>
                <b-col cols="12" class="d-flex justify-content-between">
                    <b-button
                    :disabled="submitButtonDisabled"
                    class="mt-2 col-md-5"
                    variant="primary"
                    @click="download()"
                    >
                        Изменить
                    </b-button>
                    <b-button class="mt-2 col-md-5" variant="secondary" @click="btnCancel()">
                        Отмена
                    </b-button>
                </b-col>
            </b-row>
    </b-modal>
  </example-wrapper>
  </div>
  </template>
  
  <script>
  import { Cropper } from "vue-advanced-cropper";
  import "vue-advanced-cropper/dist/style.css";
  import Vue from 'vue';
  import VerticalButtons from '@/views/default/taxi/photoControl/photo/VerticalButtons.vue'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import SquareButton from '@/views/default/taxi/photoControl/photo/SquareButton.vue';
  import ExampleWrapper from './photo/ExampleWrapper.vue';

  
  export default {
    props: ['performer_id', 'performer_assign_p_c_id','img'],
    components: {
        Cropper,
        ToastificationContent,
        Vue,
        VerticalButtons,
        SquareButton,
        ExampleWrapper,
    },
    data() {
      return {
      query: this.performer_assign_p_c_id,
      submitButtonDisabled: false,
      bodyPhoto: [],
      img2: '',
      img3:'',
      };
    },
    methods: {
    getphoto() {
      if(this.performer_id === this.performer_id && this.query == this.query) {
      this.$http
      .get(`/photo-control/performer/${this.performer_id}`, {
        params: this.query,
      }) 
      .then(res =>{
        this.img3 = res.data[0].url
        this.$store.commit('pageData/setdataCount', this.img.length)
      })
      .catch(err => {
        console.log(err);
      })
    }
    },
      flip(x, y) {
			const { image } = this.$refs.cropper.getResult();
			if (image.transforms.rotate % 180 !== 0) {
				this.$refs.cropper.flip(!x, !y);
			} else {
				this.$refs.cropper.flip(x, y);
			}
		},
    rotate(angle) {
			this.$refs.cropper.rotate(angle);
		},
    Browser() {
      const result = this.$refs.cropper.getResult().canvas.toDataURL();
      this.img2 = result
			const newTab = window.open();
			newTab.document.body.innerHTML = `<img src="${result}"></img>`;
    },
    download() {
      const result = this.$refs.cropper.getResult().canvas.toDataURL();
      this.img2 = result
      this.bodyPhoto = {
        image: this.img2,
        performer_assign_p_c_id: this.performer_assign_p_c_id
      }
      this.submitButtonDisabled = true
      this.$http
      .post(`photo-control/performer/${this.performer_id}/image`, this.bodyPhoto)
      .then(res => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Успех!',
            icon: 'CheckIcon',
            variant: 'success',
            text: 'Фото изменено',
          },
        })
        this.btnCancel()
        this.$emit('getphoto')
      })
      .catch(err => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Ошибка!',
            icon: 'XIcon',
            variant: 'danger',
            text: `Некоректные данные`,
          }
        })
      })
      .finally(() => {
        this.submitButtonDisabled = false
      })
  },
    btnCancel(){
            this.$bvModal.hide('modalPhoto')
            this.query = null
            this.bodyPho = []
            this.img2 = ''
        }
    }
  };
  </script>

<style lang="scss" scoped> 
.rotate-image-example {
	.cropper {
		max-height: 500px;
	}
.icon {
  width: 15px;
  height: 15px;
}
}
.cropper {
  height: 500px;
}
</style>